import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { MeetProvider } from "./context/MeetContext";
import Logins from "./pages/Logins";
import Register from "./pages/Register";
import MeetPage from "./pages/MeetPage";
import StartupPage from "./pages/StartupPage";
import ThankYou from "./pages/ThankYouPage";

import 'bootstrap/dist/css/bootstrap.min.css';

import "./styles/custom.css";
import Login from "./pages/Login";

const App = () => {

return (
    <MeetProvider>
      <BrowserRouter>
        <Switch>
          <Route path="/" component={StartupPage} exact />
          <Route path="/meet/:id" component={MeetPage} />
          <Route path="/thank-you" component={ThankYou} />
          <Route path="/login" component={Logins}/>
          <Route path="/register" component={Register}/>
          <Route path="/login-page" component={Login}/> 
        </Switch>
      </BrowserRouter>
    </MeetProvider>
  );
};

export default App;
