import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';

function Login() {
  const [show, setShow] = useState(true);

  if (show) {
    return (
   <>
   
   <Alert variant="danger" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>Sigtrack Main Video Board </Alert.Heading>
        <p>
          View Link here for Sigtrack Meeting Sigtrack ID Meeting. store meeting user
        </p>
      </Alert>


<div className=''>

<Table striped="columns">
      <thead>
        <tr>
          <th>#</th>
          <th>Video Name</th>
          <th>Date Created</th>
          <th>Drone Meeting link</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Sigtrack Meet1</td>
          <td>Date1</td>
          <td> <Button variant="secondary">Link</Button>
          <Button style={{ marginLeft: "20px" }} variant="secondary">Recorded Video</Button>
          </td>

        </tr>
         <tr>
         </tr>
      </tbody>
    </Table>

</div>

</>

       
      


    );
  }
  return <Button onClick={() => setShow(true)}>Show Alert</Button>;
}

export default Login;