import React, { useEffect, useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import { MeetContext } from "../context/MeetContext";

const MeetPage = ({ match }) => {
  //AS OF NOW DOMAIN WOULD BE JITSI'S AS WE ARE STILL USING THIER SERVERS
  const domain = "meet.jit.si";
  let api = {};

  const history = useHistory();

  // THIS IS TO EXTRACT THE NAME WHICH WAS FILLED IN THE FIRST PAGE
  const [name] = useContext(MeetContext);

  // INTIALISE THE MEET WITH THIS FUNCTION
  const startMeet = useCallback(() => {
  
    const options = {
      roomName: match.params.id,
      
      width: "100%",
      height: 500,
      configOverwrite: { prejoinPageEnabled:false,disableSimulcast:false, },

      interfaceConfigOverwrite: {
        
        
        // overwrite interface properties if you want
        DEFAULT_LOGO_URL:'https://w7.pngwing.com/pngs/786/126/png-transparent-logo-contracting-photography-logo-symbol.png',
        MOBILE_APP_PROMO:false,
        SHOW_CHROME_EXTENSION_BANNER:false,
        SHOW_JITSI_WATERMARK:false,
        DEFAULT_WELCOME_PAGE_LOGO_URL: 'https://w7.pngwing.com/pngs/786/126/png-transparent-logo-contracting-photography-logo-symbol.png',
       
        
        SHOW_DEEP_LINKING_IMAGE:false,
        DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
        JITSI_WATERMARK_LINK: 'https://sigtrack.org',
       
SHOW_WATERMARK_FOR_GUESTS:false,
POLICY_LOGO: null,
PROVIDER_NAME: 'App1',
DEFAULT_REMOTE_DISPLAY_NAME: 'Drone 2',

HIDE_DEEP_LINKING_LOGO:false,
SHOW_BRAND_WATERMARK: false,
notifyAllParticipants: false,

      
        SHOW_POWERED_BY: false,
// HIDE_DEEP_LINKING_LOGO: true,
// SHOW_BRAND_WATERMARK: false,
// SHOW_WATERMARK_FOR_GUESTS: false

      },
     
      
      // VIDEO FRAME WILL BE ADDED HERE
      parentNode: document.querySelector("#jitsi-iframe"),
      userInfo: {
        displayName: name,
      },
      
    };
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    api = new window.JitsiMeetExternalAPI(domain, options);

    api.addEventListeners({
      readyToClose: handleClose,
      participantLeft: handleParticipantLeft,
      participantJoined: handleParticipantJoined,
      videoConferenceJoined: handleVideoConferenceJoined,
      videoConferenceLeft: handleVideoConferenceLeft,
      
    });
  }, [api]);

  useEffect(() => {
    if (window.JitsiMeetExternalAPI) {
      startMeet();
    } else {
      alert("JitsiMeetExternalAPI not loaded");
    }
  }, [startMeet]);

  // ALL OUR HANDLERS
  const handleClose = () => {
    console.log("handleClose");
  };

  const handleParticipantLeft = async (participant) => {
    console.log("handleParticipantLeft", participant);
    await getParticipants();
   //await getSourceName();
  };

  const handleParticipantJoined = async (participant) => {
    console.log("handleParticipantJoined", participant);
    await getParticipants();
  };

  const handleVideoConferenceJoined = async (participant) => {
    console.log("handleVideoConferenceJoined.roomName", participant);
    
    await getParticipants();
  };

  const handleVideoConferenceLeft = () => {
    console.log("handleVideoConferenceLeft");
    history.push("/thank-you");
  };

  // GETTING ALL PARTICIPANTS
  const getParticipants = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(api.getParticipantsInfo());
      }, 500);
    });
  };

  return (
    <React.Fragment>
      <header
        style={{
          backgroundColor:'black',
          color: "white",
          textAlign: "center",

        }}
      >
        <p style={{ margin: 0, padding: 10 }}></p>
      </header>
      <div id="jitsi-iframe" style={{marginBottom:20}}></div>
      <div
      
        style={{
    
          height: "100%",
          margin: 0,
          
        }}
      ></div>
    </React.Fragment>
  );
};

export default MeetPage;
