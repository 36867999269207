
import React,{ } from 'react'
//import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';

//import { Link,useNavigate } from 'react-router-dom'
import Table from 'react-bootstrap/Table';

//import axios from 'axios'

function Logins() {


  return (
 
    <div>
         <Table striped bordered hover variant="dark">
      <thead>
        <tr>
          <th>#</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Username</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Mark</td>
          <td>Otto</td>
          <td>@mdo</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Jacob</td>
          <td>Thornton</td>
          <td>fat</td>
        </tr>
        <tr>
          <td>3</td>
          <td colSpan={2}>Malaka</td>
          <td>@twitter</td>
        </tr>
      </tbody>
    </Table>
    </div>
 

  )
}

export default Logins